import {useUser} from '../context/Auth'
import React, {useEffect} from 'react'

export default function UserSnapScript() {
    const user = useUser()

    useEffect(() => {
        if (!user) return
        const scriptLoadApi = document.createElement('script')
        scriptLoadApi.textContent = `window.onUsersnapLoad = function(api) {
                api.init({
                      user: {
                        userId: ${user?.id},
                        email: '${user?.email}',
                      }
                    }
                );
            }`
        document.body.appendChild(scriptLoadApi)

        const scriptSrc = document.createElement('script')
        scriptSrc.src = `https://widget.usersnap.com/global/load/0254a83c-61a5-4696-87bf-5c610aa25360?onload=onUsersnapLoad`
        document.body.appendChild(scriptSrc)
    }, [user])

    return <> </>
}
